<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol col="12" class="py-3">
						<DetailForm
							:is-admin="isAdmin"
							class="admin-appt-detail"
							@update-appt="gotoUpdateForm"
						></DetailForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { GET_APPT_BY_ID, GET_SERVICES } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("serviceAppointment")

export default {
	name: "AdminApptDetail",

	components: {
		DetailForm: () => import("./../components/DetailForm.vue"),
	},

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
		isAdmin: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {
		this.GET_SERVICES()

		this.GET_APPT_BY_ID({
			id: this.id,
		})
	},

	methods: {
		...mapActions({ GET_APPT_BY_ID, GET_SERVICES }),

		gotoUpdateForm(params) {
			this.$router.push({ name: "AdminUpdateAppt", params: { id: params.id } })
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.admin-appt-detail {
	.appt-detail {
		&__heading {
			margin-bottom: 20px;

			h5 {
				align-self: center;
				margin-right: 15px;
			}
		}

		&__container {
			display: grid;
			grid-gap: 10px 45px;
			grid-template-columns: 1fr;

			.form-group {
				display: flex;
				flex-wrap: wrap;
				gap: 1%;

				&__label {
					flex: 0 0 100%;
					max-width: 100%;
					font-weight: 500;

					@media (min-width: $xs) {
						flex: 0 0 24%;
						max-width: 24%;
					}
					@media (min-width: $ml) {
						flex: 0 0 14%;
						max-width: 14%;
					}
				}

				&__input {
					flex: 0 0 100%;
					max-width: 100%;

					@media (min-width: $xs) {
						flex: 0 0 75%;
						max-width: 75%;
					}
					@media (min-width: $ml) {
						flex: 0 0 85%;
						max-width: 85%;
					}
				}

				.contact-icon {
					display: inline-block;
					padding: 2px 8px;
					background: rgba(38, 34, 48, 0.05);
					border-radius: 100px;
					margin-right: 10px;

					img {
						width: 20px;
						height: auto;
						position: relative;
						top: -2px;
						margin-right: 10px;
					}
				}
			}
		}

		.clinic {
			.tab-content {
				max-height: 340px;
				overflow: auto;
				margin: 10px 0;
			}

			p {
				margin-bottom: 4px;
			}
			&__name {
				color: $color-black;
				font-weight: bold;
			}
			&__address {
				display: block;
				font-size: 14px;
				margin-bottom: 12px;
				color: $color-black-pearl;
			}
		}

		.date-time img {
			position: relative;
			top: -2px;
			margin-left: 20px;
		}

		.service p {
			margin-bottom: 6px;
		}

		.service-form-group {
			display: flex;
			flex-wrap: wrap;
			gap: 1%;

			&__label {
				font-size: 13px;
				color: inherit;
				font-weight: 500;
				flex: 0 0 100%;
				max-width: 100%;

				@media (min-width: $xs) {
					flex: 0 0 30%;
					max-width: 30%;
				}
			}

			&__input {
				flex: 0 0 100%;
				max-width: 100%;
				@media (min-width: $xs) {
					flex: 0 0 69%;
					max-width: 69%;
				}
			}
		}

		.panel {
			&__label {
				color: $color-siam;
			}
			&__des {
				color: $color-black-pearl;
			}
			&__link {
				color: $color-primary;
			}
		}
	}
}
</style>
